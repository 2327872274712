@import "variables";

a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
}

.email-side-box,
.social-media-side-box {
    width: 40px;
    position: fixed;
    bottom: 0px;
    z-index: 10;
    color: $light-slate;
}

.social-media-side-box {
    left: 40px;
    right: auto;
    
    ul {
        display: flex;
        flex-direction: column;
        // -webkit-box-align: center;
        align-items: center;
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
            a {
                padding: 10px;
            }

            &:last-of-type {
                margin-bottom: 20px;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 90px;
            margin: 0px auto;
            background-color: $light-slate;
        }
    }
}

.email-side-box {
    left: auto;
    right: 40px;

    .email-container {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        position: relative;

        a {
            margin: 20px auto;
            padding: 10px;
            font-family: $font-mono;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.1em;
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
        }
        
        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 90px;
            margin: 0px auto;
            background-color: $light-slate;
        }
    }
}

main {
    margin: 0px auto;
    width: 100%;

    color: $slate;
    background-color: $navy;
    font-size: 20px;
    line-height: 1.3;
    
    font-family: $font-sans;

    max-width: 1600px;
    min-height: 100vh;

    section {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        flex-direction: column;
        align-items: flex-start;
        min-height: 100vh;
        height: 100vh;
        padding: 0px;

        margin: 0px auto;
        // padding: 100px 0px;
        max-width: 1000px;
    }
}

.introduction-section {
    h1 {
        color: $green;
        font-family: $font-mono;
        margin: 0px 0px 30px 4px;
        font-size: clamp(14px, 5vw, 16px);
        font-weight: 400;
    }

    h3 {
        margin-top: 5px !important;
        color: $slate;
        line-height: 0.9;
    }

    p {
        font-size: 18px;
        margin: 40px 0px 0px;
        max-width: 540px;
        line-height: 1.5;
    }

    .big-heading {
        margin: 0px;
        font-size: clamp(40px, 8vw, 80px);
    }
}