@import "variables";

.top-banner {
    min-height: 100vh;
    background-color: $brown;

    display: flex;
    justify-content: center;
    align-items: center;

    .banner-info-container {
        display: flex;
        flex-flow: column;
        align-items: center;

        h1 {
            font-size: 300%;
            text-align: center;
            color: #353239;
            font-family: "Franklin Gothic Medium","Arial Narrow",Arial,sans-serif;
            margin: 0 auto 18px;
        }

        #title {
            height: 5vh;
            font-size: 150%;
            font-family: "Raleway",sans-serif;
            -webkit-font-smoothing: antialiased;
            font-weight: 100;
            margin-bottom: 1rem;
            text-align: center;
        }
    }
}

section.about-me {
    min-height: 100vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-heading {
        padding-top: 5%;

        font: 18px/24px "opensans-bold",sans-serif;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #000;
        text-align: center;
    }

    .intro-card-container {
        .card {
            background: #fff;
            margin: 55px 75px 30px;
            padding: 15px 15px 30px;
            text-decoration: none;
            box-shadow: 0 4px 6px rgba(0,0,0,.3);
            transition: all .2s linear;
            z-index: 0;
            position: relative;

            display: flex;
            flex-direction: column;

            img {
                height: 250px;
            }

            .icons-container {
                margin-top: 20px;
                gap: 30px;
                display: flex;
                justify-content: center;
                flex-flow: row nowrap;
            }
        }
    }

    .intro-description {
        width: 100%;
        background-color: red;
    }
}